@import "../../styles/colors";

.brown {
  position: fixed;
  top: 130px;
  right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $brown;
  width: 60px;
  height: 20px;
  border-radius: 33px;
  opacity: 0.94;
  font: normal normal bold 9px Josefin Sans;
  letter-spacing: -0.72px;
  text-transform: uppercase;
  translate: 30px 340px;
  z-index: 11;
}

.yellow {
  display: flex;
  justify-content: start;
  align-items: center;
  color: $brown;
  font: normal normal bold 13px Josefin Sans;
  background-color: yellow;
  padding-left: 7px;
  letter-spacing: -1.04px;
  line-height: 0.8;

  width: 66px;
  height: 64px;
  /* UI Properties */
  border-radius: 14px;
}

.logo {
  width: 87px;
  height: 58px;

  @media (max-width: $maxres) {
    margin-top: 10px;
    margin-right: 20px;
    translate: -5px;
    width: 45px;
    height: 38px;
  }
}

.footer {
  position: fixed;
  cursor: pointer;
  top: 480px;
  right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 145px;
  height: 64px;
  background-color: white;
  border-radius: 14px;
  z-index: 91;

  @media (max-width: $maxres) {
    width: 55px;
    right: 50px;
    justify-content: center;
  }
}

.modal {
  background-color: black;
  width: 98%;
  height: 98vh;
  border-radius: 100px;
  margin-left: 10px;
  margin-top: 10px;
  opacity: 0.8;
  z-index: 100;
  position: absolute;

  .modalBody {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    z-index: 100;
  }

  .yellowModal {
    display: flex;
    justify-content: start;
    align-items: center;
    color: $brown;
    font: normal normal bold 30px/26px Josefin Sans;
    letter-spacing: -2.4px;
    color: #554533;
    background-color: #edd112;
    padding-left: 7px;
    line-height: 0.8;

    width: 160px;
    height: 140px;
    /* UI Properties */
    border-radius: 14px;
    margin-bottom: 30px;
  }

  .brownModal {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $brown;
    width: 100px;
    height: 30px;
    border-radius: 33px;
    opacity: 0.94;
    font: normal normal bold 15px/6px Josefin Sans;
    letter-spacing: -1.2px;
    text-transform: uppercase;
    translate: 80px 20px;
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-top: 5%;
  }

  .column {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    img {
      width: 150px;
      height: 110px;
      margin-bottom: 30px;
    }
  }

  .closeModal {
    background-color: $yellow;
    border-color: $yellow;
    border-style: solid;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    position: relative;
    left: 85%;
    top: 40px;
    z-index: 100;
  }

  h1 {
    color: $yellow;
  }

  .cap {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      padding-top: 5px;
      width: 100px;
      height: 20px;
      background-color: $yellow;
      color: black;
      text-align: left;
      font: normal normal bold 12px Josefin Sans;
      letter-spacing: -0.96px;
      text-transform: uppercase;
      transform: rotate(-90deg) translateX(-100px) translateY(-25px);
    }

    h3 {
      text-transform: uppercase;
      font: normal normal bold 12px Josefin Sans;
      color: $yellow;
      margin-left: 20px;
    }

    .links {
      color: white;
      margin-left: 20px;
      text-align: left;

      li {
        text-decoration: underline;
        font: normal normal normal 9px/12px Josefin Sans;
        letter-spacing: -0.72px;
        cursor: pointer;
      }
    }
  }

  .embeds {
    display: flex;
    flex-direction: row;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 100%;

    .credits {
      display: flex;
      align-items: center;
      justify-content: center;

      text-align: left;
      font: normal normal bold 15px/18px Josefin Sans;
      letter-spacing: -1.2px;
      color: #edd112;
      width: 100px;
      height: 30px;
      border-radius: 30px;
      background-color: $greenbtn;
    }
    .social {
      display: flex;
      color: white;
      width: 100px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      height: 30px;
    }
  }
}
