@import "../../styles/colors";
@import "../../styles/fonts";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  background: $yellow 0% 0% no-repeat padding-box;
  text-transform: uppercase;
  font: normal normal bold Josefin Sans;
  height: 24px;
  width: 160px;
  margin-bottom: 25px;
  color: $brown;
  opacity: 0.75;
}

.titleCap {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: $yellow;
  border-radius: 18px;
  height: 40px;
  width: 518px;
  margin-bottom: 15px;
  color: $brown;
  font: normal normal bold Josefin Sans;
  opacity: 0.8;
}

.title {
  text-align: center;
}

.social {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  color: white;
  width: 167px;
  height: 22px;
  margin-bottom: 25px;
}

.branch {
  position: absolute;
  translate: 0px 250px;
  z-index: 10;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;

  img {
    margin-right: 10px;
  }

  .link1, .link2, .link3{
    display: flex;
    flex-direction: column;
    

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 5px;
      padding-right: 5px;
      width: 103px;
      height: 31px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 13px;
      opacity: 1;
      font: normal normal 600 11px/10px Josefin Sans;
      letter-spacing: -0.33px;
      color: $brown;
      translate: 20px -10px;

      p{
        width: 90px;
      }
    }
  }

  .dw3{

    width: 203px !important;

  }

  .img1, .img2{
    width: 155px;
    height: 181px;
  }

  .img3 {
    width: 317px;
    height: 185px;
  }
}
