@import "../../styles/colors";
@import "../../styles/fonts";


.root{
  display: flex; 
  flex-direction: column;
  align-items: start;
  border-bottom: 5px solid $greenbtn;
}

.body{
  display: flex; 
  flex: 10;
  flex-direction: row;
  margin: 88px;

  @media (max-width: $maxres) {
    margin: 40px;
  }

  .logo{
    translate: -25px ;
    margin-bottom: 50px;
  }

  .text{
    width: 55%;
    color: #FFFFFF;

    .titleCron{
      margin-top: -50px;
      
      font: normal normal bold 30px/37px Josefin Sans;
      text-shadow: 2px 2px 2px black;
      

      h3{
        font: $font-style-italic normal 200 30px/37px $font-family-josefin-sans;
        text-shadow: 2px 2px 2px black;
      }
    }

    .content{
      width: 100%;
      text-align: justify;
      font: $font-style-normal normal normal 14px/14px $font-family-josefin-sans;
    }
  }


  .audio{
    color: white;
    display: flex;
    flex-direction: row;
    width: 190px;
    font-size: 25px;
    .audioStick{
      margin-left: 25px;
      text-align: center;
      width: 152px;;
      height: 27px;
      /* UI Properties */
      border: 2px solid white;
      border-radius: 8px;
      opacity: 0.91;
      font: $font-style-italic normal 400 12px/30px $font-family-josefin-sans;
      text-transform: uppercase;
    }

  }

  $rhap_background-color: transparent !default;

  .player{
    margin-top: 25px;    

    @media (max-width: $maxres) {
      width: 280px !important;
    }
  }

  .rhap_repeat-button{
    display:none;
  }

  .rhap_play-pause-button{
    
  }

}

.title{
  text-align: center;
  text-shadow: 2px 2px 2px black;
}


.footer{
  margin-top: 100px;
  width: 100%;
  translate: 0px -50px;
  display: flex;
  justify-content: center;
  flex: 2
}

  

