@import "../../styles/colors";
@import "../../styles/fonts";

.audioPlayer {
  display: flex;
  flex-direction: row;
  width: 400px;
  height: 16px;

  @media (max-width: $maxres) {
    width: 280px;
  }
}

.audioControls {
  button {
    background-color: transparent;
    color: white !important;
    font-size: 16px;
    border: none;
    background-color: transparent;
    margin-right: 8px;
    cursor: pointer;
    svg {
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }    
  }
}

.timeCurrent{
  display: inline-block;
  font: normal normal bold 12px Josefin Sans;
  letter-spacing: -0.72px;
  margin-right: 10px;
  padding-right: 2px;
  text-shadow: 2px 2px 2px black;
}

.progressBar {

  translate: -5px -3px;
  width: 80%;
  /* =========================
Custom Input range 
=============================*/

  /* Input range - chrome and safari */

  input[type="range"] {
    --range-progress: 0;
    -webkit-appearance: none;
    position: relative;
    background: #ccc;
    width: 80%;
    height: 5px;
    border-radius: 3px;
    cursor: pointer;
  }

  /* Input range - firefox */
  input[type="range"]::-moz-range-track {
    position: relative;
    background: #ccc;
    width: 80%;
    height: 5px;
    border-radius: 3px;
    cursor: pointer;
  }

  /* played progress length - Chrome & safari*/
  input[type="range"]::before {
    content: "";
    height: 5px;
    background: #33c0c4;
    width: var(--range-progress);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* played progress length - firefox */
  input[type="range"]::-moz-range-progress {
    background: #33c0c4;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    height: 2px;
  }

  /* slider thumb - chrome and safari */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    background-color: white;
    cursor: pointer;
    position: relative;
  }

  /* dragging thumb - chrome and safari */
  input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
  }

  /* slider thumb - firefox */
  input[type="range"]::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: transparent;
    position: relative;
  }
  /* dragging thumb - firefox */
  input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
  }
}

.volumeControl {
  button {
    font-size: 16px;
    background-color: transparent;
    color: white !important;
    font-size: 16px;
    border: none;
    background-color: transparent;
    margin-right: 8px;
    cursor: pointer;
  }

  /* =========================
  Custom Input range 
  =============================*/

  /* Input range - chrome and safari */

  input[type="range"] {
    --range-progress: 0;
    -webkit-appearance: none;
    position: relative;
    background: #ccc;
    width: 100%;
    height: 5px;
    border-radius: 3px;
    cursor: pointer;
  }

  /* Input range - firefox */
  input[type="range"]::-moz-range-track {
    position: relative;
    background: #ccc;
    width: 80%;
    height: 5px;
    border-radius: 3px;
    cursor: pointer;
  }

  /* played progress length - Chrome & safari*/
  input[type="range"]::before {
    content: "";
    height: 5px;
    background: #33c0c4;
    width: var(--range-progress);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* played progress length - firefox */
  input[type="range"]::-moz-range-progress {
    background: #33c0c4;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    height: 2px;
  }

  /* slider thumb - chrome and safari */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    background-color: white;
    cursor: pointer;
    position: relative;
  }

  /* dragging thumb - chrome and safari */
  input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
  }

  /* slider thumb - firefox */
  input[type="range"]::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: transparent;
    position: relative;
  }
  /* dragging thumb - firefox */
  input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
  }
}
