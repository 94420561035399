@import "../../styles/colors";
@import "../../styles/fonts";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 5px solid $greenbtn;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.body {
  display: flex;
  flex-direction: row;
  height: 60vh;
  width: 80%;
  margin: 88px;
  align-items: end;
  z-index: 20;
  .text {
    flex-direction: column;
    width: 55%;
    color: #ffffff;
    z-index: 20;

    .titleCron {
      h2 {
        text-transform: uppercase;
        font: $font-style-normal normal bold 30px/37px $font-family-josefin-sans;
        text-shadow: 2px 2px 2px black;
      }
    }

    .content {
      width: 602px;
      text-align: justify;
      font: $font-style-normal normal 100 14px/14px $font-family-josefin-sans;
    }
  }


  .videoControls {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 400px;
    height: 16px;

    @media (max-width: $maxres) {
      width: 250px;
    }

    button {
      background-color: transparent;
      color: white !important;
      font-size: 16px;
      border: none;
      background-color: transparent;
      margin-right: 8px;
      cursor: pointer;
      svg {
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
      } 
    }
  }

  .pipe{
    width: 1px;
    height: 27px;
    background-color: $yellow;
  }

  .progressBar {

    translate: -5px -3px;
    width: 100%;
    /* =========================
  Custom Input range 
  =============================*/
  
    /* Input range - chrome and safari */
  
    input[type="range"] {
      --range-progress: 0;
      -webkit-appearance: none;
      position: relative;
      background: #ccc;
      width: 100%;
      height: 5px;
      border-radius: 3px;
      cursor: pointer;
    }
  
    /* Input range - firefox */
    input[type="range"]::-moz-range-track {
      position: relative;
      background: #ccc;
      width: 100%;
      height: 5px;
      border-radius: 3px;
      cursor: pointer;
    }
  
    /* played progress length - Chrome & safari*/
    input[type="range"]::before {
      content: "";
      height: 5px;
      background: #33c0c4;
      width: var(--range-progress);
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
    }
  
    /* played progress length - firefox */
    input[type="range"]::-moz-range-progress {
      background: #33c0c4;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      height: 2px;
    }
  
    /* slider thumb - chrome and safari */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      border: none;
      background-color: white;
      cursor: pointer;
      position: relative;
    }
  
    /* dragging thumb - chrome and safari */
    input[type="range"]:active::-webkit-slider-thumb {
      transform: scale(1.2);
    }
  
    /* slider thumb - firefox */
    input[type="range"]::-moz-range-thumb {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
      border: transparent;
      position: relative;
    }
    /* dragging thumb - firefox */
    input[type="range"]:active::-moz-range-thumb {
      transform: scale(1.2);
    }
  }
  
  .volumeControl {
    button {
      font-size: 16px;
      background-color: transparent;
      color: white !important;
      font-size: 16px;
      border: none;
      background-color: transparent;
      margin-right: 8px;
      cursor: pointer;
    }
  
    /* =========================
    Custom Input range 
    =============================*/
  
    /* Input range - chrome and safari */
  
    input[type="range"] {
      --range-progress: 0;
      -webkit-appearance: none;
      position: relative;
      background: #ccc;
      width: 100%;
      height: 5px;
      border-radius: 3px;
      cursor: pointer;
    }
  
    /* Input range - firefox */
    input[type="range"]::-moz-range-track {
      position: relative;
      background: #ccc;
      width: 100%;
      height: 5px;
      border-radius: 3px;
      cursor: pointer;
    }
  
    /* played progress length - Chrome & safari*/
    input[type="range"]::before {
      content: "";
      height: 5px;
      background: #33c0c4;
      width: var(--range-progress);
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
    }
  
    /* played progress length - firefox */
    input[type="range"]::-moz-range-progress {
      background: #33c0c4;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      height: 2px;
    }
  
    /* slider thumb - chrome and safari */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      border: none;
      background-color: white;
      cursor: pointer;
      position: relative;
    }
  
    /* dragging thumb - chrome and safari */
    input[type="range"]:active::-webkit-slider-thumb {
      transform: scale(1.2);
    }
  
    /* slider thumb - firefox */
    input[type="range"]::-moz-range-thumb {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
      border: transparent;
      position: relative;
    }
    /* dragging thumb - firefox */
    input[type="range"]:active::-moz-range-thumb {
      transform: scale(1.2);
    }
  }

  .audio {
    color: white;
    display: flex;
    flex-direction: row;
    width: 190;
    font-size: 25px;
    font: normal normal bold 9px/7px Josefin Sans;
    text-transform: uppercase;
    z-index: 20;

    .audioStick {
      z-index: 20;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 90px;
      height: 27px;
      /* UI Properties */
      border: 1px solid $greenbtn;
      border-radius: 8px;
      opacity: 0.91;
      background-color: $greenbtn;
    }
  }

  $rhap_background-color: transparent !default;

  .rhap_repeat-button {
    display: none;
  }

  .rhap_play-pause-button {
  }

  .playBtn {
    display: flex;
    flex-direction: column;
    font-size: 150px;
    justify-items: center;
    padding: 70px;
    width: 150px;
    color: #ffffff;
    opacity: 0.55;
    position: relative;
    margin: 50px;
    border: 5px solid white;
    border-radius: 160px;
  }
}

.title {
  text-align: center;
  text-shadow: 2px 2px 2px black;
}

.btn {
  /* Layout Properties */
  width: 199px;
  height: 35px;
  /* UI Properties */
  background-color: $greenbtn;
  border-color: $greenbtn;
  border-radius: 18px;
  opacity: 0.91;
  text-align: center;
  font: $font-style-italic normal 600 10px/11px $font-family-josefin-sans;
  color: white;
}
