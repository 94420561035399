@import "../../styles/colors";
@import "../../styles/fonts";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  border-bottom: 5px solid $greenbtn;
}

.body {
  display: flex;
  flex: 10;
  flex-direction: row;
  margin: 88px;
  width: 80%;

  .text {
    color: #ffffff;
    .titleCron {
      h2 {
        font: normal normal bold 30px/37px Josefin Sans;
        letter-spacing: -2.4px;
        color: #ffffff;
      }

    }

    .content {

      @media (max-width: $maxres) {
        width: 300px;
      }

      h3 {
        text-align: left;
        font: italic normal 200 30px/37px Josefin Sans;
        letter-spacing: 0px;
        color: white;
        text-shadow: 0px 0px 6px #00000029;
      }
      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $aqua-dark;
        border-radius: 5px;
      }

      width: 520px;
      text-align: justify;
      font: $font-style-normal normal 100 14px/14px $font-family-josefin-sans;
      scroll-behavior: smooth;
      padding-right: 50px;
      white-space: pre-line;
    }
  }

  $rhap_background-color: transparent !default;

  .rhap_repeat-button {
    display: none;
  }

}

.title {
  text-align: center;
}

.btn{
  flex:2;
}
