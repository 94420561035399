@import "../../styles/colors";
@import "../../styles/fonts";


.social{
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  width: 167px;
  height: 22px;
  margin-bottom: 20px;
  z-index: 12;
}

  

