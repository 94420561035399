@import "../../styles/colors";
@import "../../styles/fonts";


.root{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;

  @media (max-width: $maxres) {
    height: 300px !important;
  }
}

.content{
  background-color: $brown;
  color: white;
  width: 50%;
  padding: 30px;
  font: $font-style-normal normal 100 14px/14px $font-family-josefin-sans;
  opacity: 0.8;
  z-index: 3;

  @media (max-width: $maxres) {
    width: 80%;
    font: $font-style-normal normal 100 8px/8px $font-family-josefin-sans;
  }
}

.subtitle{
  font: $font-style-normal normal 100 14px/14px $font-family-josefin-sans;
  text-transform: none;
  padding-bottom: 20px;
}

.title{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  background: white;
  text-transform: uppercase;
  font: normal normal bold 40px/48px Josefin Sans;
  padding-left: 100px;
  padding-right: 100px;
  height: 100px;
  padding-top: 20px;
  margin-bottom: 25px;
  color: $brown;
  opacity: 0.7;
  
  @media (max-width: $maxres) {
    font: normal normal bold 30px/24px Josefin Sans;
    height: 80px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.titleContainer{
  display: flex;
  flex-direction: row;

  .bird{
    position: absolute;
    translate: -120px -80px;
    z-index: 10;
    height: 203px;
    opacity: 0.9;

    @media (max-width: $maxres) {
      height: 180px;
      translate: -80px -90px;
    }
  }

  .plant{
    position: absolute;
    translate: 500px -30px;
    z-index: 10;
    height: 130px;

    @media (max-width: $maxres) {
      height: 130px;
      translate: 350px -30px;
    }
  }
}

.cloud{
  position: absolute;
  translate: -15px 180px;
  z-index: 1;
  height: 190px;

  @media (max-width: $maxres) {
    height: 100px;
    translate: -15px 120px;
  }
}

.frog{
  height: 100px;
  transform: scaleX(-1);
  translate: 0px 20px;
  z-index: 2;

  @media (max-width: $maxres) {
    height: 50px;
    translate: 0px -30px;
  }
}

.titleCap{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 60px;
  height: 60px;
  width: 332px;
  margin-top: 100px;
  margin-bottom: 25px;
  color: $brown;
  text-transform: uppercase;
  z-index: 10;
  opacity: 0.7;
  font: normal normal bold 40px/48px Josefin Sans;

  @media (max-width: $maxres) {
    font: normal normal bold 30px/24px Josefin Sans;
  }
}

.line{
  height: 60px;
  width: 3px;
  background-color: #554533;
  translate: 0px;
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0.35;
}

.title{
  text-align: center;
}

.branch{
  position: absolute;
  translate: 0px 240px;
  z-index: 1;
  width: 30%;

  @media (max-width: $maxres) {
    height: 50px;
    translate: 0px 155px;
  }
}