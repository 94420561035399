@import "../../styles/colors";
@import "../../styles/fonts";

.root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 260px;
  border-bottom: 5px solid $yellow;
  border-top: 5px solid $yellow;

  @media (max-width: $maxres) {
    flex-direction: column;
  }
}

.title {
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  text-transform: uppercase;
  font-family: $font-family-josefin-sans;
  width: 200px;
  height: 150px;
  background-color: $brown;
  color: $yellow;
  text-transform: uppercase;
  font: normal normal bold 30px/37px Josefin Sans;
  text-shadow: 2px 2px 2px black;
  @media (max-width: $maxres) {
    font: normal normal bold Josefin Sans;
    translate: 0px -230px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 30%;
  margin-right: 45px;
  @media (max-width: $maxres) {
    height: 0px;
  }
  
  .bird {
    position: absolute;
    z-index: 10;
    height: 200px;
    translate: -150px 70px;

    @media (max-width: $maxres) {
      height: 150px;
      transform: scaleX(-1);
      translate: -100px 10px;
    }
  }

  @media (max-width: $maxres) {
    margin-left: 145px;
    justify-content: center;
  }
}

.subtitle {
  display: flex;
  text-align: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
  border-radius: 20px;
  color: $yellow;
  font-family: $font-family-josefin-sans !important;
  text-transform: uppercase;
  font: normal normal bold 24px/22px Josefin Sans;
  text-shadow: 2px 2px 2px black;
  
  @media (max-width: $maxres) {
    margin-top: 0px;
    width: 100%;
    justify-content: center;
  }
}

.content {
  width: 700px;

  @media (max-width: $maxres) {
    width: 300px;
    margin-left: 50px;
  }

  p {
    color: $brown;
    font-family: $font-family-josefin-sans;

    @media (max-width: $maxres) {
      color: white;
      text-shadow: 2px 2px black;
      display: flex;
      text-align: center;
      align-items: center;
    }
  }
}

.title {
  text-align: center;
}

.branchTop {
  position: absolute;
  translate: 200px -50px;
  z-index: 10;
  width: 770px;

  @media (max-width: $maxres) {
    display: none
  }
}

.branchBottom {
  position: absolute;
  translate: -200px 210px;
  z-index: 10;
  width: 770px;
  @media (max-width: $maxres) {
    width: 100%;
    translate: 0px 390px;
  }
}

.logo {
  translate: 0px -20px;
  width: 137px;
  height: 76px;
}
