@import "../../styles/colors";
@import "../../styles/fonts";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vw;
  background-color: $videoback;
  border-bottom: 5px solid $greenbtn;

  @media (max-width: $maxres) {
    height: 120vw;
  }
}

.videoContainer {
  width: 60%;
  align-self: center;
  margin-bottom: 25px;

  @media (max-width: $maxres) {
    width: 90%;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: start;
  text-transform: uppercase;
  padding-left: 100px;
  color: white;
  height: 80px;

  @media (max-width: $maxres) {
    padding-left: 20px;
  }

  h1 {
    margin-left: 20px;
    font: $font-style-normal normal 300 34px/34px $font-family-josefin-sans;

    @media (max-width: $maxres) {
      padding-left: 5px;
      font-size: 22px;
    }
  }

  .text{
    margin: 20px;
    width: 20%;
    text-transform: none;
    margin-left: 20px;
    p{
      color: white;
      font: $font-style-normal normal 100 14px/14px $font-family-josefin-sans;
    }

    @media (max-width: $maxres) {
      width: 120px;
    }
  }

  .logo{

    @media (max-width: $maxres) {
      width: 120px;
    }
  }

  .line {
    height: 60px;
    width: 1px;
    background-color: #ccbe117e;
    margin-left: 20px;

    @media (max-width: $maxres) {
      padding-left: 0px;
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  translate: 0px -10px;
}
