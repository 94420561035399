@import "../../styles/colors";
@import "../../styles/fonts";

.videoplayercontainer{
display: flex;
justify-content: center;
}
.videoplayer{
      width: 100%; // Reset width
      height: 100%; // Reset height
      position: relative;
 }
.videoplayercontrols{
    position: absolute;
    bottom: 30px;
    display: flex;
    justify-content: space-around;
    align-items: end;
    width: 100%;
    opacity: 0;
    button{
    background: transparent;
    border: none;
    color: white;
    font-size: 20px;

    @media (max-width: $maxres) {
      bottom: 10px;
    }
    }

}
.preload{
  position: absolute;
  width: 100%; // Reset width
  height: 100%; // Reset height
  z-index: 99;
  button{
    background: transparent;
    border: none;
    opacity: 0.5;
    color: $gray-mdm;
    font-size: 150px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    }
    img{
      position: absolute;
      width: 100%;
      height: 100%;
    }
}
.videoplayer:hover > .videoplayercontrols{
  opacity: 1;
  transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -webkit-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
}

.text{
  margin-top: 20px;
  position: absolute;
  width: 50%;
  text-transform: none;
  margin-left: 20px;
  background-color: $brown;
  padding: 20px;
  opacity: 0.8;
  p{
    font: $font-style-normal normal 100 18px/18px $font-family-josefin-sans;
    color: white;
  }

  @media (max-width: $maxres) {
    p{
      font: $font-style-normal normal 100 8px/8px $font-family-josefin-sans;
      color: white;
    }
    width: 220px;
  }
}

.SeekBar{
  flex-grow: 0.8;
  height: 5px;
  display: flex;
  translate: 0px -10px;
  align-items: center;
    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      height: 5px;
      border-radius: 5px;
      background: #e0e0e0; /* Track color */
      outline: none;
    }
    
    /* Styles for the progress (filled part) of the range input */
    input[type="range"]::-webkit-slider-runnable-track {
      height: 5px;
      border-radius: 5px;
      background: #33c0c4; /* Progress color */
    }
    
    /* Styles for the thumb (slider handle) of the range input */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffffff; /* Thumb color */
      translate: 0px -3px;
    }
    
    /* Styles for the thumb (slider handle) when dragged */
    input[type="range"]:active::-webkit-slider-thumb {
      background: #ffffff; /* Thumb color when active */
    }
  
}
.VolumenCont{
  display: flex;
  flex-direction: column;
  position: relative;
}
.Volumen{
  transform: rotate(-90deg);
  height: 130px;
  align-items: baseline;
  position: absolute;
  top: -130px;
  width: 130px;
  left: 10px;

    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      height: 5px;
      border-radius: 5px;
      background: #e0e0e0; /* Track color */
      outline: none;
      margin: 10px 0;
    }
    
    /* Styles for the progress (filled part) of the range input */
    input[type="range"]::-webkit-slider-runnable-track {
      height: 5px;
      border-radius: 5px;
      background:  #33c0c4; /* Progress color */
    }
    
    /* Styles for the thumb (slider handle) of the range input */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffffff; /* Thumb color */
      margin-top: -5px;
    }
    
    /* Styles for the thumb (slider handle) when dragged */
    input[type="range"]:active::-webkit-slider-thumb {
      background: #ffffff; /* Thumb color when active */
    }
}
.Duration{
  display: flex;
    align-items: center;
    height: 30px;
    time{
    background-color: rgb(255,255,255 ,0.5);
    width: 100%;
    border-radius: 9px;
    padding: 0px 15px;
    color: white;
    }
}
.title {
  display: flex;
  align-items: center;
  justify-content:  start;
  text-transform: uppercase;
  font: normal normal bold Josefin Sans;
  padding-left: 100px;
  color: white;
  h1{
    margin-left: 20px;
  }

  .line{
    height: 60px;
    width: 1px;
    background-color: #CCBE117E;
    margin-left: 20px;
  }
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
