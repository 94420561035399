@import "../../styles/colors";
@import "../../styles/fonts";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 431px;


  @media (min-width: $minres) and (max-width: $maxres) {
    height: 100vw;
  }

  @media (max-width: $minres) {
    height: 200vw;
  }
}

.bg {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.r {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 431px;
}

.logorow {
  display: flex;
  flex-direction: row;
  margin-top: 120px;

  @media (max-width: $maxres) {
    margin-top: 10px;
    max-width: 250px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

.logoTitle {
  width: 522px;
  margin-bottom: -80px;

  @media (max-width: $maxres) {

    width: 210px;
    margin-bottom: 10px;
    margin-left: 100px;
  }
}

.logomed {
  width: 380px;
}

.logobear {
  width: 150px;
  margin-left: 120px;
}

.logo1 {
  width: 100px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.logo2 {
  height: 40px;
  width: 40px;
  margin-top: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.logos {
  width: 643px;
  height: 94px;
}
