@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,500;1,700&display=swap');

/* Font/text values */
$font-family-font-awesome-6-free: Font Awesome 6 Free;
$font-family-josefin-sans: Josefin Sans;
$font-family-raleway: Raleway;
$font-family-strasua: Strasua;
$font-style-normal: normal;
$font-style-italic: italic;
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-medium: medium;
$font-size-18: 18px;
$font-size-30: 30px;
$font-size-40: 40px;
$character-spacing-0: 0px;
$character-spacing--2-4: -2.4px;
$character-spacing--3-2: -3.2px;
$line-spacing-22: 22px;
$line-spacing-36: 36px;
$line-spacing-37: 37px;
$line-spacing-48: 48px;
$text-transform-uppercase: uppercase;


/* Character Styles */
.character-style-1 {
font-family: var($font-family-josefin-sans);
font-style: var($font-style-normal);
font-weight: var($font-weight-bold);
font-size: var($font-size-30);
line-height: var($line-spacing-37);
letter-spacing: var($character-spacing--2-4);
color: 'white'
}
.character-style-2 {
font-family: var($font-family-josefin-sans);
font-style: var($font-style-normal);
font-weight: var($font-weight-bold);
font-size: var($font-size-40);
line-height: var($line-spacing-48);
letter-spacing: var($character-spacing--3-2);
color: 'white';
text-transform: var($text-transform-uppercase);
}
.character-style-3 {
font-family: var($font-family-josefin-sans);
font-style: var($font-style-normal);
font-weight: var($font-weight-bold);
font-size: var($font-size-30);
line-height: var($line-spacing-37);
letter-spacing: var($character-spacing--2-4);
color: 'white';
text-transform: var($text-transform-uppercase);
}
.character-style-4 {
font-family: var($font-family-font-awesome-6-free);
font-style: var($font-style-normal);
font-weight: var($font-weight-normal);
font-size: var($font-size-30);
line-height: var($line-spacing-36);
letter-spacing: var($character-spacing-0);
color: 'white'
}
.character-style-5 {
font-family: var($font-family-raleway);
font-style: var($font-style-italic);
font-weight: var($font-weight-medium);
font-size: var($font-size-18);
line-height: var($line-spacing-22);
letter-spacing: var($character-spacing-0);
color: 'white'
}
.character-style-6 {
font-family: var($font-family-strasua);
font-style: var($font-style-normal);
font-weight: var($font-weight-normal);
font-size: var($font-size-40);
line-height: var($line-spacing-48);
letter-spacing: var($character-spacing-0);
color: 'white'
}