@import "../../styles/colors";
@import "../../styles/fonts";


.wrapper {
  display: flex;
  flex-direction: column;
  color: $greenbtn;
  width: 199px;
  font-size: 50px;

  svg{
    z-index: 99;
  }

}

.btn{
  /* Layout Properties */
  width: 199px;
  height: 35px;
  /* UI Properties */
  background-color: $greenbtn;
  border-color: $greenbtn;
  border-radius: 18px;
  opacity: 0.91;
  text-align: center;
  font: $font-style-italic normal 600 10px/11px $font-family-josefin-sans;
  color: white;
  text-transform: uppercase;
  border: 0px;
}

  

