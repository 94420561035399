$graydark:#707070;
$yellow: #EDD112;
$browng: #776909;
$brown-light: #C39C88;
$greenbtn: #95A80A;
$aqua1: #31C0C4;
$aqua-light: #6DC0C5;
$petroleum: #005C72;
$gray-lighter: #00000029;
$aqua-dark:#63A1A0;
$brown: #554533;
$green-olive: #8B9E0D;
$gray-mdm: #A5A5A5;
$green-lighter: #ACCE99;
$green-live:#64981D;
$aqua-live:#00D8FF;
$videoback: #00393B;

$maxres: 900px;
$minres: 640px;



