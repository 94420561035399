@import "../../styles/colors";
@import "../../styles/fonts";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 5px solid $greenbtn;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.body {
  display: flex;
  flex-direction: row;
  height: 60vh;
  width: 80%;
  margin: 88px;
  align-items: end;
  z-index: 20;

  .text {
    flex-direction: column;
    width: 55%;
    color: #ffffff;
    z-index: 20;

    .titleCron {
      h2 {
        text-transform: uppercase;
        font: $font-style-normal normal bold 30px/37px $font-family-josefin-sans;
      }
    }

    .content {
      width: 602px;
      text-align: justify;
      font: $font-style-normal normal 100 14px/14px $font-family-josefin-sans;
    }
  }

  .audio {
    color: white;
    display: flex;
    flex-direction: row;
    width: 190;
    font-size: 25px;
    font: normal normal bold 9px/7px Josefin Sans;
    text-transform: uppercase;
    z-index: 20;

    .audioStick {
      z-index: 20;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 90px;
      height: 27px;
      /* UI Properties */
      border: 1px solid $greenbtn;
      border-radius: 8px;
      opacity: 0.91;
      background-color: $greenbtn;
    }
  }

  $rhap_background-color: transparent !default;

  .player {
    margin-top: 50px;
    width: 420px;
  }

  .rhap_repeat-button {
    display: none;
  }

  .rhap_play-pause-button {
  }

  .playBtn {
    display: flex;
    flex-direction: column;
    font-size: 150px;
    justify-items: center;
    padding: 70px;
    width: 150px;
    color: #ffffff;
    opacity: 0.55;
    position: relative;
    margin: 50px;
    border: 5px solid white;
    border-radius: 160px;
  }
}

.pipe{
  width: 1px;
  height: 27px;
  background-color: $yellow;
}

.title {
  text-align: center;
}

.btn {
  /* Layout Properties */
  width: 199px;
  height: 35px;
  /* UI Properties */
  background-color: $greenbtn;
  border-color: $greenbtn;
  border-radius: 18px;
  opacity: 0.91;
  text-align: center;
  font: $font-style-italic normal 600 10px/11px $font-family-josefin-sans;
  color: white;
}
