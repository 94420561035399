@import "../../styles/colors";


.btnModal {
  background-color: $yellow;
  border-color: $yellow;
  border-style: solid;
  height: 30px;
  width: 80px;
  position: fixed;
  top: 50px;
  left: 50px;
  z-index: 101;
  font: normal normal bold 20px/28px Josefin Sans;
  text-transform: uppercase;
  color: $brown;
}

.menu {
  position: fixed;
  top: 100px;
  right: 50px;
}

.modal {
  background-color: black;
  width: 95%;
  height: 98vh;
  border-radius: 100px;
  margin-left: 10px;
  margin-top: 10px;
  opacity: 0.8;
  z-index: 100;
  position: absolute;

  @media (max-width: $maxres) {
    width: 90%;
  }

  .modalBody {
    margin-top: 50px;
    margin-left: 30px;
    height: 70%;
    z-index: 100;
  }

  .row {
    display: flex;
    flex-direction: row;

    @media (max-width: $minres) {
      max-width: 250px;
      flex-direction: column;
      overflow-y: scroll;
      max-height: 450px;
    }
  }

  .closeModal {
    background-color: $yellow;
    border-color: $yellow;
    border-style: solid;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    position: relative;
    left: 85%;
    top: 40px;
    z-index: 100;
  }

  h1 {
    color: $yellow;
    font: normal normal bold 40px/48px Josefin Sans;
    text-transform: uppercase;
  }

  .cap {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      padding-top: 5px;
      width: 100px;
      height: 20px;
      background-color: $yellow;
      color: black;
      text-align: left;
      font: normal normal bold 12px Josefin Sans;
      letter-spacing: -0.96px;
      text-transform: uppercase;
      transform: rotate(-90deg) translateX(-100px) translateY(-25px);
    }

    h3 {
      text-transform: uppercase;
      font: normal normal bold 12px Josefin Sans;
      color: $yellow;
      margin-left: 20px;
    }

    .links {
      color: white;
      margin-left: 20px;
      text-align: left;

      li {
        text-decoration: underline;
        font: normal normal normal 9px/12px Josefin Sans;
        letter-spacing: -0.72px;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 100%;

    .credits {
      display: flex;
      align-items: center;
      justify-content: center;

      text-align: left;
      font: normal normal bold 15px/18px Josefin Sans;
      letter-spacing: -1.2px;
      color: #edd112;
      width: 100px;
      height: 30px;
      border-radius: 30px;
      background-color: $greenbtn;
    }
    .social {
      display: flex;
      color: white;
      width: 100px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      height: 30px;
    }
  }
}
