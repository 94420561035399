@import "../../styles/colors";

.root {
}

.menu {
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: 99;
}


