@import "../../styles/colors";


.btnModal {
  img{
    width: 317px;
    height: 185px;
  }
}
.modal {
  background-color: black;
  width: 98%;
  height: 98vh;
  border-radius: 100px;
  margin-left: 10px;
  margin-top: 10px;
  opacity: 0.8;
  z-index: 100;
  position: absolute;

  .modalBody {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    z-index: 100;
  }

  .row {
    width: 60%;
    margin-top: 5%;
  }

  .closeModal {
    background-color: $yellow;
    border-color: $yellow;
    border-style: solid;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    position: relative;
    left: 85%;
    top: 40px;
    z-index: 100;
  }

  h1 {
    color: $yellow;
  }

  .cap {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      padding-top: 5px;
      width: 100px;
      height: 20px;
      background-color: $yellow;
      color: black;
      text-align: left;
      font: normal normal bold 12px Josefin Sans;
      letter-spacing: -0.96px;
      text-transform: uppercase;
      transform: rotate(-90deg) translateX(-100px) translateY(-25px);
    }

    h3 {
      text-transform: uppercase;
      font: normal normal bold 12px Josefin Sans;
      color: $yellow;
      margin-left: 20px;
    }

    .links {
      color: white;
      margin-left: 20px;
      text-align: left;

      li {
        text-decoration: underline;
        font: normal normal normal 9px/12px Josefin Sans;
        letter-spacing: -0.72px;
        cursor: pointer;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 100%;

    .credits {
      display: flex;
      align-items: center;
      justify-content: center;

      text-align: left;
      font: normal normal bold 15px/18px Josefin Sans;
      letter-spacing: -1.2px;
      color: #edd112;
      width: 100px;
      height: 30px;
      border-radius: 30px;
      background-color: $greenbtn;
    }
    .social {
      display: flex;
      color: white;
      width: 100px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      height: 30px;
    }
  }
}
