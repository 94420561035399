@import "../../styles/colors";
@import "../../styles/fonts";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vw;
  

  @media (min-width: $minres) and (max-width: $maxres) {
    height: 100vw;
  }

  @media (max-width: $minres) {
    height: 200vw;
  }
}



.logoTitle {
  width: 522px;

  @media (max-width: 1290px) and (min-width: 1270px)  {
    position: relative;
    top:20px;
  }

  @media (max-width: $maxres) {
    width: 210px;
    margin-bottom: 10px;
    margin-left: 100px;
  }
}

.logorow{
  display: flex;
  flex-direction: row;
  margin-top: 220px;

  @media (min-width: $minres) and (max-width: $maxres) {
    margin-top: -25px;
    max-width: 750px;
    flex-wrap: wrap;
  }

  @media (max-width: $minres) {
    margin-top: 10px;
    max-width: 250px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

}

.logos {
  width: 643px;
  height: 94px;
}

.logo1 {
  width: 100px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.logo2 {
    height: 40px;
    width: 40px;
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
}

.title {
  text-align: center;
  font: $font-style-normal normal bold 40px/22px $font-family-josefin-sans;
  color: $brown;

  @media (max-width: 1290px) and (min-width: 1270px)  {
    position: relative;
    top:20px;
  }

  @media (max-width: $maxres) {
    font: $font-style-normal normal bold 16px $font-family-josefin-sans;
    color: #554533;
    max-width: 250px;
    flex-wrap: wrap;
  }
}



.content {
  p {
    width: 602px;
    text-align: center;
    color: $brown;
    font-family: $font-family-josefin-sans;
    font: $font-style-normal normal medium 18px $font-family-josefin-sans;
    letter-spacing: -0.9px;

    @media (max-width: 1290px) and (min-width: 1270px)  {
      position: relative;
      top:20px;
    }

    @media (max-width: $maxres) {
      font: $font-style-normal normal bold 14px $font-family-josefin-sans;
      color: #554533;
      max-width: 300px;
      flex-wrap: wrap;
    }
  }
}

.btn{
  margin-top: 0px;
}