@import "../../styles/colors";
@import "../../styles/fonts";


.root{
  display: flex; 
  flex-direction: column;
  align-items: center;
  height: 80vh;
  border-bottom: 5px solid $greenbtn;
  
}

.body{
  display: flex; 
  flex: 10;
  flex-direction: row;
  margin: 88px;
  width: 80%;

.sticks{
  display: flex;
  flex-direction: row;
}

  .text{
    color: #FFFFFF;
    .titleCron{
      h2{
        font: $font-style-normal bold 200 30px/37px $font-family-josefin-sans;
      }

      h3{
        font: $font-style-italic normal 200 30px/37px $font-family-josefin-sans;
      }
    }

    .content{
      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $aqua-dark;
        border-radius: 5px;
      }

      width: 50%;
      text-align: justify;
      font: $font-style-normal normal 100 14px/14px $font-family-josefin-sans;
      max-height: 25vw;
      overflow-y: scroll;
      scroll-behavior: smooth;
      padding-right: 50px;
      margin-top: 25px;
      white-space: pre-line;

      @media (max-width: $maxres) {
        margin-top: 20px;
        width: 330px;
        max-height: 300px;
        padding-right: 20px;
      }

      
    }
  }

  .audioMuted {
    color: white;
    display: flex;
    flex-direction: row;
    width: 190;
    font-size: 25px;
    font: normal normal bold 9px/7px Josefin Sans;
    text-transform: uppercase;
    z-index: 20;

    .pipe{
      width: 1px;
      height: 27px;
      background-color: $yellow;
    }

    .audioStickM {
      z-index: 20;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 90px;
      height: 27px;
      /* UI Properties */
      border: 1px solid $greenbtn;
      border-radius: 8px;
      opacity: 0.91;
      background-color: $greenbtn;
    }
  }

  .audio{
    color: white;
    display: flex;
    flex-direction: row;
    width: 190;
    font-size: 25px;

    .pipe{
      width: 1px;
      height: 27px;
      background-color: $yellow;
    }

    .audioStick{
      margin-left: 25px;
      text-align: center;
      width: 152px;;
      height: 27px;
      /* UI Properties */
      border: 1px solid white;
      border-radius: 8px;
      opacity: 0.91;
      font: $font-style-italic normal 200 12px/30px $font-family-josefin-sans;
      text-transform: uppercase;
    }

  }

  $rhap_background-color: transparent !default;

  .player{
    margin-top: 50px;    
  }

  .rhap_repeat-button{
    display:none;
  }

  .rhap_play-pause-button{
    
  }

  .playBtn{
    display: flex;
    flex-direction: column;
    font-size: 150px;
    justify-items: center;
    padding: 70px;
    width: 150px;
    color: #FFFFFF;
    opacity: 0.55;
    position: relative;
    margin: 50px;
    border: 5px solid white;
    border-radius: 160px;
  }
}

.title{
  text-align: center;
}


.btn{
  flex:2
}

  

