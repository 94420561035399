@import "../../styles/colors";
@import "../../styles/fonts";
.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}
.title {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  background: black 0% 0% no-repeat padding-box;
  text-transform: uppercase;
  font: normal normal bold Josefin Sans;
  height: 54px;
  width: 160px;
  margin-bottom: 25px;
  color: $yellow;
  opacity: 0.75;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 270px;
  margin-left: 100px;
  margin-right: 50px;
  p {
    letter-spacing: 0;
    text-align: center;
    font: italic normal 600 14px/14px Josefin Sans;
  }
}
.logo {
  height: 244px;
}
.mono {
  position: absolute;
  height: 216px;
  translate: -55px 130px ;
  z-index: 100;
}
.trivia {
  width: 426px;
  height: 241px;
  margin-top: 25px;
}
.btnTrivia{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $greenbtn;
  color: $yellow;
  text-align: center;
  font: normal normal bold 30px/37px Josefin Sans;
  border-radius: 26px;
  width: 300px;
  height: 44px;
  translate: 60px -20px;
}
.social {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  color: $brown;
  width: 167px;
  height: 22px;
  margin-bottom: 25px;
}

.branch {
    position: absolute;
    translate: 0px 190px;
    z-index: 10;
    width: 100%;
  }