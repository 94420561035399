@import "../../styles/colors";
@import "../../styles/fonts";


.root{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;

  @media (max-width: $maxres) {
    height: 300px !important;
  }
}

.content{
  background-color: $brown;
  color: white;
  width: 50%;
  padding: 30px;
  font: $font-style-normal normal 100 14px/14px $font-family-josefin-sans;


  @media (max-width: $maxres) {
    width: 80%;
    font: $font-style-normal normal 100 8px/8px $font-family-josefin-sans;
  }
  opacity: 0.8;
}

.subtitle{
  font: $font-style-normal normal 100 14px/14px $font-family-josefin-sans;
  text-transform: none;
  padding-bottom: 20px;

  @media (max-width: $maxres) {
    padding-bottom: 10px;
  }
}

.title{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  background: $yellow;
  text-transform: uppercase;
  padding-left: 100px;
  padding-right: 100px;
  height: 100px;
  margin-bottom: 25px;
  color: $brown;
  font: normal normal bold 40px/48px Josefin Sans;
  padding-top: 20px;

  @media (max-width: $maxres) {
    font: normal normal bold 30px/24px Josefin Sans;
    height: 80px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.titleContainer{
  display: flex;
  flex-direction: row;

  .bird{
    position: absolute;
    translate: -120px -80px;
    z-index: 10;
    height: 203px;
    opacity: 0.9;

    @media (max-width: $maxres) {
      height: 150px;
      translate: -50px -90px;
    }
  }

  .plant{
    position: absolute;
    translate: 350px -30px;
    z-index: 10;
    height: 130px;

    @media (max-width: $maxres) {
      height: 100px;
      translate: 260px -30px;
    }
  }

  .cloud{
    position: absolute;
    translate: -15px -150px;
    z-index: 1;
    height: 190px;
  }
}

.titleCap{
  display: flex;
  text-align: center;
  align-items: end;
  justify-content: center;
  background-color: $yellow;
  border-radius: 60px;
  height: 60px;
  width: 332px;
  margin-bottom: 25px;
  color: $brown;
  font-family:  "Josefin Sans";
  font: normal normal bold 45px Josefin Sans;
  text-transform: uppercase;
  z-index: 10;

  @media (max-width: $maxres) {
    font: normal normal bold 30px/24px Josefin Sans;
  }
}

.line{
  height: 60px;
  width: 3px;
  background-color: #554533;
  translate: 0px;
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0.35;
}

.title{
  text-align: center;
}

.branch{
  position: absolute;
  translate: 0px 250px;
  z-index: 10;
  width: 100%;

  @media (max-width: $maxres) {
    translate: 0px 150px;
  }

}

  

