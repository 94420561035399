@import "../../styles/colors";
@import "../../styles/fonts";

.root {
  display: flex;
  flex-direction: column;

  @media (max-width: $maxres) {
    display: none;
  }
}

.title {
  text-align: center;
  background-color: $yellow;
  width: 77px;
  height: 24px;
  /* UI Properties */
  border-radius: 33px;
  opacity: 0.94;
  font: normal normal bold 10px/25px Josefin Sans;
  z-index: 100;
}

.capitulosText {
  display: flex;
  text-transform: uppercase;
  font: normal normal bold 13px/16px Josefin Sans;
  letter-spacing: -1.04px;
  translate: -70px 35px;
  color: white;
  flex-direction: column;
  z-index: 99;

  .capitulo {
    margin-bottom: 33px;
    color: white;

    &:visited{
      color: white;
    }

    &:hover{
      cursor: pointer;
    }
  }
}

.river {
  position: absolute;
  translate: -10px -50px;
  height: 500px;
  z-index: 90;
}

.brown {
  position: fixed;
  top: 130px;
  right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $brown;
  width: 60px;
  height: 20px;
  border-radius: 33px;
  opacity: 0.94;
  font: normal normal bold 9px Josefin Sans;
  letter-spacing: -0.72px;
  text-transform: uppercase;
  translate: 30px 340px;
  z-index: 92;
}

.intro {
  position: fixed;
  translate: 10px -20px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $brown;
  width: 60px;
  height: 20px;
  border-radius: 33px;
  opacity: 0.94;
  font: normal normal bold 9px Josefin Sans;
  letter-spacing: -0.72px;
  text-transform: uppercase;
  z-index: 92;
}
